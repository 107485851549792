<template>
  <div>
    <el-dialog v-if="dialogVisible_printHC" :title="dialogTitle" :visible="dialogVisible_printHC"
               :close-on-click-modal="false" width="900px"
               custom-class="cus_dialog cus_dialog_print"
               @close="closeDialog">

      <div style="text-align: right; position: absolute; top:2px; right: 50px;">
        <el-button @click="preSaveExcel" icon="el-icon-printer" type="warning">导出EXCEL</el-button>
      </div>
      <div style="width: 750px; margin: 0 auto; overflow-y: auto;">
        <div id="printMe" style=" width:740px; margin: 0 auto;">
          <div class="printtable" style="margin-top: 20px;">
            <table id="exportTable111">
              <tr>
                <th colspan="8">
              <div style="font-size: 24px;font-weight: 700;">{{ dialogTitle }}</div>
                </th>
              </tr>
              <tr>
                <th colspan="8">
                  <div class="flexStretch flexBetween" style="width: 100%;padding: 10px;">
                    <div class="flexStartUp flex_1">
                      <div style="white-space: nowrap;font-weight: 700;">系别:</div>
                      <div style="white-space: nowrap;">{{ searchForm.dept_name }}</div>
                    </div>
                    <div class="flexStartUp flex_1" style="margin-left: 10px;">
                      <div style="white-space: nowrap;font-weight: 700;">实训基地名称:</div>
                      <div></div>
                    </div>
                    <div class="flexStartUp flex_1" style="margin-left: 10px;">
                      <div style="white-space: nowrap;font-weight: 700;">管理员:</div>
                      <div></div>
                    </div>
                  </div>
                </th>
              </tr>
              <tr>
                <th>序号</th>
                <th v-for="(item,index) in exportColumns" :key="index" style="word-break: break-word;">
                  {{ item.name }}
                </th>
              </tr>
              <tr v-for="(item,index) in exportList" :key="index" style="word-break: break-word;">
                <td>{{ index + 1 }}</td>
                <td v-for="(cc,cidx) in exportColumns" :key="cidx+'colums'">
                  {{ item[cc.field] }}
                </td>
              </tr>
            </table>
          </div>

        </div>

      </div>


    </el-dialog>
  </div>
</template>

<script>
import FileSaver from 'file-saver';
import * as XLSX from "xlsx";

export default {

  props: ['dialogTitle', 'dialogVisible_printHC', 'searchForm'],
  data() {
    return {
      exportColumns: [
        {name: '名称', field: 'title', value: true, disabled: false,},
        {name: '品牌、型号', field: 'PX', value: true, disabled: true,},
        {name: '采购数量', field: 'cgNum', value: true, disabled: false,},
        {name: '单位', field: 'jldw', value: true, disabled: false,},
        {name: '计划单位成本\n（元）', field: 'cgJine', value: true, disabled: false,},
        {name: '计划总成本\n（元）', field: 'cgTotal', value: true, disabled: false,},
        {name: '备注', field: 'user_remark', value: true, disabled: false,},
      ],
      exportList: [],
    }
  },
  mounted() {
    this.getExportList()
  },
  methods: {
    getExportList() {
      return new Promise(resolve => {
        let _this = this
        let params = {}, arr = []
        params.title = _this.searchForm.title
        params.page = 1
        params.pagesize = 10000000000
        params.type = 'sx'
        params.sDate = _this.searchForm.dateRange ? `${_this.searchForm.dateRange[0]} 00:00:00` : ''
        params.eDate = _this.searchForm.dateRange ? `${_this.searchForm.dateRange[1]} 23:59:59` : ''

        params.title = ''
        params.dept_id = _this.searchForm.dept_id ? _this.searchForm.dept_id : ''

        this.$http.post('/api/sx_sg_hc_list', params).then(res => {
          if (res.data && res.data.length > 0) {
            res.data.map(a => {
              a.PX = `${a.pinpai} ${a.xinghao}`
              if (a.sp_status == 0) {
                a.sp_status_Name = '待审核'
              }
              if (a.sp_status == 1) {
                a.sp_status_Name = '审核通过'
              }
              if (a.sp_status == 2) {
                a.sp_status_Name = '审核驳回'
              }
              a.match = 1
              a.listLen = 0
              if (a.hc_list) {
                a.list = JSON.parse(a.hc_list)
                a.desc = ``
                a.list && a.list.map(h => {
                  a.desc = `${a.desc}${h.title || '-'}${h.cgNum || '-'}${h.jldw};`
                  if (h.cgNum != h.rkNum) {
                    a.match = 0
                  }
                })
                a.listLen = a.list.length
              }
            })
            this.exportList = res.data
            console.log(107107107, this.exportList)
          }
          resolve()
        })
      })
    },
    preSaveExcel() {
      let _this = this
      if (!this.exportList || this.exportList.length == 0) {
        this.$message.warning('暂无数据可导出~')
        return
      }
      setTimeout(function () {
        _this.saveExcel(_this.dialogTitle)
      }, 500)
    },
    saveExcel(saveTitle) {
      var xlsxParam = {raw: true}
      let wb = XLSX.utils.table_to_book(document.querySelector('#exportTable111'), xlsxParam);
      let wbout = XLSX.write(wb, {bookType: 'xlsx', bookSST: true, type: 'array'});
      try {
        FileSaver.saveAs(new Blob([wbout], {type: 'application/octet-stream'}), `${saveTitle}.xlsx`);
      } catch (e) {
        if (typeof console !== 'undefined')
          console.log(e, wbout)
      }
      return wbout
    },

    closeDialog() {
      this.$$parent(this, 'dialogVisible_printHC', false)
    }
  }
}
</script>

<style scoped="scoped" lang="less">
.uline {
  text-decoration: underline;
}

.printtable {
  width: 100%;
  border-collapse: collapse;

  table {
    border-right: 1px solid #000;
    border-bottom: 1px solid #000;;

  }
}

.printtable td, .printtable th {
  border-left: 1px solid #000;
  border-top: 1px solid #000;
  padding: 20px 10px;
  text-align: center;
}
</style>
